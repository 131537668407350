.App {
  height: 100vh;
  width: 100vw;
}

.Bar {
  height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  border-bottom: 2px solid #9C9C9C;
  background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(0,112,48,1) 100%);
  box-shadow: 0 2px 2px -2px #1366A0;
}
.logoimage {
  max-width: 80px;
}

.headertext {
  font-size: 1.5em;
  color: #fff;
  margin: 0;
  padding: 0;
  margin-left: 10px;
  vertical-align: middle
}

.userinfobar {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  margin-right: 10px;
  margin-left: 2vw
}

.userinfoimg {
  border-radius: 50%;
  max-width: 40px;
  margin-left: 2vw;
}

.btn {
  appearance: none;
  background-color: #2ea44f;
  border: 1px solid rgba(27, 31, 35, .15);
  border-radius: 6px;
  box-shadow: rgba(27, 31, 35, .1) 0 1px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system,system-ui,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji";
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding: 6px 16px;
  margin-left: 2vw;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  white-space: nowrap;
  width: fit-content;
}

.btn:focus:not(:focus-visible):not(.focus-visible) {
  box-shadow: none;
  outline: none;
}

.btn:hover {
  background-color: #2c974b;
}

.btn:focus {
  box-shadow: rgba(46, 164, 79, .4) 0 0 0 3px;
  outline: none;
}

.btn:disabled {
  background-color: #94d3a2;
  border-color: rgba(27, 31, 35, .1);
  color: rgba(255, 255, 255, .8);
  cursor: default;
}

.btn:active {
  background-color: #298e46;
  box-shadow: rgba(20, 70, 32, .2) 0 1px 0 inset;
}

.footer {
  height: fit-content;
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: right;
}
